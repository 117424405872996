<template>
  <OsButton
    class="model-chooser-item-container inline-button"
    :class="{ 'model-chooser-item-container--active': isSelected }"
    :title="title"
    @click="$emit('click')"
  >
    <div
      class="model-chooser-item"
      :class="{ 'model-chooser-item--nobadge': !badge }"
    >
      <BadgeItem v-if="badge" class="badge-label mx-auto" dark>{{
        badge
      }}</BadgeItem>

      <ImagePicture
        class="model-chooser-item__image my-auto"
        provider="contentful"
        format="webp,png"
        :width="295"
        :height="175"
        :alt="title"
        :src="imageUri.replace('https:', '')"
        loading="lazy"
        sizes="xs:295px sm:350"
        densities="1"
      />
      <div class="model-chooser-item__title text-lg text-gray-950">
        <span><slot /> </span>
      </div>
    </div>
  </OsButton>
</template>

<script lang="ts">
export default {
  props: {
    isSelected: {
      type: Boolean,
      required: true
    },
    imageUri: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    badge: {
      type: String,
      required: false,
      default: undefined
    }
  },

  emits: ['click']
}
</script>

<style lang="scss">
.model-chooser-item-container {
  padding: 0;

  &.inline-button {
    min-width: 100%;
    max-width: 100%;
  }

  &--active {
    .model-chooser-item {
      box-shadow:
        inset 4px 4px 0px $blue,
        inset -4px -4px 0px $blue;
    }
  }

  .model-chooser-item {
    display: flex;
    flex-direction: column;
    aspect-ratio: 296 / 370;
    border-radius: rem(4);
    height: 100%;
    width: 100%;
    padding: rem(8);
    background-image: url('/images/offer/museum-bg-md.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &__title {
      height: 40px;
      text-wrap: wrap;
      display: flex;
      span {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-evenly;

        @include viewport('md', 'max-width') {
          font-size: 16px;
        }
        @include viewport('sm', 'max-width') {
          font-size: 14px;
        }
      }
    }
    &--nobadge {
      padding-top: rem(40);
    }

    .image--error {
      background-image: url('/images/vehicle/defaults/missing-mode-chooser.png');
      background-size: contain;
      background-color: transparent;
      &::before {
        content: '';
      }
    }
    .badge-label {
      min-height: rem(32);
    }
  }
}
</style>
