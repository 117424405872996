<template>
  <div class="model-chooser-models">
    <div class="row">
      <div
        v-for="model in availableModels"
        :key="model.Id"
        :class="`col-${hasRestrictedModels ? 'c-lg-3 col-6' : 'c-lg-4 col-6'}`"
      >
        <FilterModelSearchResultItem
          :is-selected="selectedModelIds.includes(model.Id)"
          :image-uri="getImage(model.ImageURL3D)"
          :title="`${getBrandDescription(model)} ${model.Description}`"
          :badge="getModelBadge(model)"
          @click="$emit('select-model', model)"
        >
          {{ model.Description }}
        </FilterModelSearchResultItem>
      </div>
    </div>
    <div class="row align-center">
      <div class="col-auto text-base text-gray-950">Your selected models</div>
      <div class="col-auto">
        <div v-if="selectedModels.length > 0" class="row">
          <div v-for="model in selectedModels" :key="model.Id" class="col-auto">
            <OsButton
              class="inline-button"
              @click="$emit('select-model', model)"
            >
              <BadgeItem is-active>
                {{ model.Description }}
              </BadgeItem>
            </OsButton>
          </div>
        </div>
        <span v-else class="text-base text-gray-500">None</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IFilterDataBrandModel } from '~/types/filterData'
import { IGoogleDataLayerOptions } from '~/types/googleDataLayer'
import { useFilterDataStore } from '~/stores/filterDataStore'
import { ModelChooserKey } from '~/components/filter/model/index.vue'
import { ECondition } from '~/types/vehicle'

export default {
  props: {
    vehicleCondition: {
      type: Number as PropType<ECondition>,
      required: true
    },
    trackingOptions: {
      type: Object as PropType<Partial<IGoogleDataLayerOptions>>,
      default: null,
      required: false
    },
    models: {
      type: Array as PropType<IFilterDataBrandModel[]>,
      default: () => [],
      required: true
    },
    selectedModels: {
      type: Array as PropType<IFilterDataBrandModel[]>,
      default: () => [],
      required: false
    },
    selectedModelIds: {
      type: Array as PropType<number[]>,
      default: () => [],
      required: false
    },
    restrictedModels: {
      type: Array as PropType<number[]>,
      default: () => [],
      required: false
    },
    hasRestrictedModels: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  emits: ['select-model'],

  setup(props) {
    const filterDataStore = useFilterDataStore()

    const brandByModelId = inject(ModelChooserKey, {})

    const availableModels = computed(() =>
      props.models
        .filter((model) => !!model)
        .sort((a, b) => a.Priority - b.Priority)
    )

    const availableSelectedModels = computed(() =>
      props.selectedModels.filter((model) => !!model)
    )

    const getBrandDescription = (model: IFilterDataBrandModel) =>
      brandByModelId[model.Id]?.Description || ''

    const getImage = (source?: string, quality = 75): string => {
      if (!source || source === undefined) {
        return '/images/vehicle/defaults/missing-mode-chooser.png'
      }
      return `${source}?fm=webp&q=${quality}`
    }

    const hybridModels = computed<number[]>(() =>
      filterDataStore
        .getModelsByFuel(props.vehicleCondition, [3])
        .map((x) => x.Id)
    )

    const electricModels = computed<number[]>(() =>
      filterDataStore
        .getModelsByFuel(props.vehicleCondition, [4])
        .map((x) => x.Id)
    )

    const getModelBadge = (model: IFilterDataBrandModel) => {
      if (hybridModels.value.includes(model.Id)) return 'Hybrid Available'
      if (electricModels.value.includes(model.Id)) return 'Electric'

      return ''
    }

    return {
      availableModels,
      availableSelectedModels,
      getModelBadge,
      getImage,
      getBrandDescription
    }
  }
}
</script>
