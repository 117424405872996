<template>
  <div class="model-search row row--container">
    <div v-if="!hasRestrictedModels" class="col-12 col-c-md-auto">
      <InputRadioGroup
        v-model="radioSelected"
        :items="radioItems"
        class="mb-6"
        item-value="Id"
        item-text="Description"
        @update:model-value="selectRadioButton"
      >
        <template #default="{ item }">
          {{ selectedBrandName(item) }}
        </template>
      </InputRadioGroup>
      <InputDropdown
        v-model="selectedBodyStyleDropdown"
        :items="bodyStyleItemsDropdown"
        :disabled="isPopularModelsSelected"
        label="Select body style"
        class="mb-6 body-style__dropdown"
      />
    </div>

    <div class="col-12 col-c-md">
      <FilterModelSearchResults
        :vehicle-condition="vehicleCondition"
        :tracking-options="{
          pageCategory: 'filter',
          category: 'filters'
        }"
        :models="models"
        :is-tev-only="isTevOnly"
        :selected-models="selectedModels"
        :selected-model-ids="selectedModelIds"
        :has-restricted-models="hasRestrictedModels"
        @select-model="selectModel"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { IGoogleDataLayerOptions } from '~/types/googleDataLayer'
import {
  IFilterDataBrand,
  IFilterDataBrandModel,
  IFilterDataFilter
} from '~/types/filterData'
import { ECondition } from '~/types/vehicle'

export const ModelChooserKey: InjectionKey<Record<number, IFilterDataBrand>> =
  Symbol.for('ModelChooserKey')

export default {
  name: 'FilterModel',

  props: {
    vehicleCondition: {
      type: Number as PropType<ECondition>,
      required: true
    },
    trackingOptions: {
      type: Object as PropType<Partial<IGoogleDataLayerOptions>>,
      default: null,
      required: false
    },
    selectedModelIds: {
      type: Array as PropType<number[]>,
      default: () => [],
      required: false
    },
    isTevOnly: {
      type: Boolean,
      default: false,
      required: false
    },
    restrictedModels: {
      type: Array as PropType<number[]>,
      default: () => [],
      required: false
    },
    popularModels: {
      type: [Array, Boolean] as PropType<string[] | boolean>,
      default: () => [],
      required: false
    }
  },

  emits: ['select-model'],

  setup(props, { emit }) {
    const { $dataLayer } = useNuxtApp()

    const {
      brandsById,
      bodyStylesById,
      modelsById,
      brandByModelId,
      modelsByBodyStyle
    } = useBrands(props.vehicleCondition)

    const isNew = computed(() => props.vehicleCondition === ECondition.New)

    const brands = computed(() => Object.values(brandsById))

    // sort brands -> EQ first and then the other three brands
    const selectedBrand = ref<IFilterDataBrand>(brands.value[0])

    const selectedBodyStyle = ref<IFilterDataFilter>(
      selectedBrand.value.BodyStyles[0]
    )

    provide(ModelChooserKey, brandByModelId)

    const hasRestrictedModels = computed(
      () => !!props.restrictedModels && !!props.restrictedModels.length
    )

    // popular models
    const hasPopularModels = computed(
      () =>
        props.popularModels &&
        Array.isArray(props.popularModels) &&
        props.popularModels.length
    )
    const popularModels = computed(() => ({
      Id: 'popularModels',
      Description: 'Featured models',
      Models: (Array.isArray(props.popularModels) ? props.popularModels : [])
        .map((id) => modelsById[Number(id)])
        .filter(Boolean)
    }))

    const radioItems = computed(() => {
      return [
        ...(hasPopularModels.value ? [popularModels.value] : []),
        ...brands.value
      ].filter((brand) => brand?.Models.length > 0)
    })

    const radioSelected = ref<IFilterDataBrand | typeof popularModels.value>(
      hasPopularModels.value ? popularModels.value : selectedBrand.value
    )
    const isPopularModelsSelected = computed(
      () => radioSelected.value.Id === popularModels.value.Id
    )

    onMounted(() => {
      if (hasRestrictedModels.value) return

      if (props.selectedModelIds.length) {
        selectedBrand.value =
          brandByModelId[props.selectedModelIds[0]] || brands.value[0]

        selectedBodyStyle.value =
          bodyStylesById[modelsById[props.selectedModelIds[0]]?.BodyStyleId] ||
          selectedBrand.value.BodyStyles[0]
      }
    })

    const selectedBrandName = (brand: IFilterDataBrand) => {
      switch (brand.Description) {
        case 'Passenger Car':
          return 'Mercedes-Benz'
        case 'AMG':
          return 'Mercedes-AMG'
        case 'EQ':
          return 'Electric models'
        case 'Maybach':
          return 'Mercedes-Maybach'
        default:
          return brand.Description
      }
    }

    // bodyStyleItemsDropdown and selectedBodyStyleDropdown could be removed if the dropdown is refactored to accept different "data structure"
    const bodyStyleItemsDropdown = computed(() =>
      selectedBrand.value.BodyStyles.map((bodyStyle) => ({
        id: bodyStyle.Id,
        text: bodyStyle.Description
      }))
    )

    const selectedBodyStyleDropdown = computed({
      get() {
        return {
          id: selectedBodyStyle.value.Id,
          text: selectedBodyStyle.value.Description
        }
      },
      set(val) {
        selectedBodyStyle.value = bodyStylesById[val.id]

        $dataLayer.linkClick({
          ...props.trackingOptions,
          category: 'Select Body Style',
          action: `${selectedBrandName(selectedBrand.value)}:${
            selectedBodyStyle.value.Description
          }`
        })
      }
    })

    const models = computed<IFilterDataBrandModel[]>(() => {
      if (hasRestrictedModels.value) {
        return props.restrictedModels.map((id) => modelsById[id])
      }

      if (isPopularModelsSelected.value) return popularModels.value.Models
      if (!selectedBodyStyle.value) return []

      // [OS-3665][OS-3700] Manually excluding EQC for NEW.
      return isNew.value
        ? modelsByBodyStyle[selectedBodyStyle.value.Id].filter(
            (model) => model.Id !== 103
          ) || []
        : modelsByBodyStyle[selectedBodyStyle.value.Id]
    })

    const selectedModels = computed<IFilterDataBrandModel[]>(() =>
      props.selectedModelIds
        .map((id) => modelsById[id])
        .filter((model) => !!model?.Id)
    )

    const selectModel = (model: IFilterDataBrandModel) => {
      emit('select-model', model)
      const brandDescription = brandByModelId[model.Id].Description

      $dataLayer.linkClick({
        ...props.trackingOptions,
        category: 'select model',
        action:
          brandDescription && model.Description
            ? `models:${brandDescription.toLowerCase()}:${model.Description.toLowerCase()}`
            : ''
      })
    }

    const selectBrand = (brand: IFilterDataBrand): void => {
      selectedBrand.value = brand
      selectedBodyStyle.value = brand.BodyStyles?.[0]
      $dataLayer.linkClick({
        ...props.trackingOptions,
        action: `brand:${selectedBrandName(selectedBrand.value)}`
      })
    }

    const selectRadioButton = (
      item: IFilterDataBrand | typeof popularModels.value
    ): void => {
      if (isPopularModelsSelected.value) return

      selectBrand(item as IFilterDataBrand)
    }

    return {
      isPopularModelsSelected,
      bodyStyleItemsDropdown,
      selectedBodyStyleDropdown,
      selectedModels,
      radioItems,
      radioSelected,
      models,
      selectedBrandName,
      hasRestrictedModels,
      selectRadioButton,
      selectModel
    }
  }
}
</script>

<style lang="scss" scoped>
.body-style__dropdown {
  min-width: rem(250);
  width: 100%;
}
</style>
